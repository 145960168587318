import React from "react"
import ReactLoading from "react-loading"

import Style from "./LoadingInput.style"

interface LoadingInputProps {
  isLoading: boolean
  loadingColor: string
}

// React.DetailedHTMLProps<
//     React.InputHTMLAttributes<HTMLInputElement>,
//     HTMLInputElement
//   >

const LoadingInput = ({
  isLoading,
  loadingColor,
}: LoadingInputProps): JSX.Element => {
  const title = isLoading ? "" : "Submit"
  const input = <Style.Input disabled={isLoading} type="submit" value={title} />
  const spinner = isLoading ? (
    <Style.Spinner
      type="spin"
      color={loadingColor}
      height="35px"
      width="35px"
    />
  ) : null

  return (
    <Style.Container>
      {spinner}
      {input}
    </Style.Container>
  )
}

export default LoadingInput
