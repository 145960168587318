import React, { useState, useEffect } from "react"

interface ICanValidate {
  isValid: boolean
  setIsValid: (isValid: boolean) => void
  validation: (input: string) => boolean
  onValidationChange: (isValid: boolean) => void
}

export type ValidatedInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  ICanValidate

export const ValidatedInput = ({
  children,
  onChange,
  validation,
  onValidationChange,
  isValid,
  setIsValid,
  ...props
}: ValidatedInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
    // only validate after change event
    setIsValid(validation(event.target.value))
  }

  useEffect(() => {
    onValidationChange(isValid)
  }, [isValid])

  return (
    <input {...props} onChange={handleChange}>
      {children}
    </input>
  )
}

export type ValidatedTextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  ICanValidate

export const ValidatedTextArea = ({
  children,
  onChange,
  validation,
  onValidationChange,
  isValid,
  setIsValid,
  ...props
}: ValidatedTextAreaProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event)
    }
    // only validate after change event
    setIsValid(validation(event.target.value))
  }

  useEffect(() => {
    onValidationChange(isValid)
  }, [isValid])

  return (
    <textarea {...props} onChange={handleChange}>
      {children}
    </textarea>
  )
}
