import React, { useState, useEffect } from "react"
import Style from "./contact-form.style"
import { colors } from "../styleguide/colors"
import { ValidatedInputProps } from "./contact-input"
import LoadingInput from "./LoadingInput"

export interface ISubmitContactForm {
  (email: string, subject: string, message: string): void
}

export enum SubmitState {
  DEFAULT,
  SUBMITTING,
  SUCCESS,
  FAILURE,
}

export interface IContactForm {
  onSubmit: ISubmitContactForm
  submitState: SubmitState
}

interface InputState {
  isValid: boolean
  input: string
}

interface InputFieldProps {
  label: string
  labelColor?: string
  type?: string
  name: string
  value: string
  isMultiLine?: boolean
  stateSetter: (value: React.SetStateAction<InputState>) => void
  stateGetter: InputState
  validation?: (input: string) => boolean
}

// For some reason can't pass props through styled with {...props}
// * Important * any props wanting to be passed through must be
// explicitly done, until I figure out what's up
const TextField = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    InputFieldProps
) => {
  const handleChange = (value: string) => {
    props.stateSetter(prev => {
      return {
        ...prev,
        input: value,
      }
    })
  }

  const handleValidationChange = (isValid: boolean) => {
    props.stateSetter(prev => {
      return {
        ...prev,
        isValid: isValid,
      }
    })
  }

  const validate = (input: string) => {
    if (props.validation) {
      return props.validation(input)
    }
    return true
  }

  if (props.isMultiLine === true) {
    return (
      <Style.Label>
        {props.label}
        <Style.MultiLineField
          name={props.name}
          value={props.value}
          onChange={event => handleChange(event.target.value)}
          validation={validate}
          onValidationChange={handleValidationChange}
          isValid={props.stateGetter.isValid}
          setIsValid={isValid => {
            props.stateSetter(prev => {
              return {
                ...prev,
                isValid: isValid,
              }
            })
          }}
        />
      </Style.Label>
    )
  } else {
    return (
      <Style.Label>
        {props.label}
        <Style.Input
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={event => handleChange(event.target.value)}
          validation={validate}
          onValidationChange={handleValidationChange}
          isValid={props.stateGetter.isValid}
          setIsValid={isValid => {
            props.stateSetter(prev => {
              return {
                ...prev,
                isValid: isValid,
              }
            })
          }}
        />
      </Style.Label>
    )
  }
}

const resetTextField = (
  stateSetter: (value: React.SetStateAction<InputState>) => void
) => {
  stateSetter({
    isValid: false,
    input: "",
  })
}

const ContactForm = ({ onSubmit, submitState }: IContactForm) => {
  const [subjectState, setSubjectState] = useState<InputState>({
    isValid: false,
    input: "",
  })
  const [messageState, setMessageState] = useState<InputState>({
    isValid: false,
    input: "",
  })
  const [emailState, setEmailState] = useState<InputState>({
    isValid: false,
    input: "",
  })
  const [isValidForm, setIsValidForm] = useState(false)
  const [didAttemptSubmit, setDidAttemptSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isValidText = (input: string): boolean => {
    if (input.length === 0 || !input.trim()) {
      return false
    }
    return true
  }

  // very basic, I'd rather them send an imperfect email than never get it all
  const isValidEmail = (input: string): boolean => {
    const expression = /\S+@\S+/
    return expression.test(input.toLowerCase())
  }

  useEffect(() => {
    const isValid =
      emailState.isValid && subjectState.isValid && messageState.isValid
    setIsValidForm(
      emailState.isValid && subjectState.isValid && messageState.isValid
    )
  }, [emailState.isValid, subjectState.isValid, messageState.isValid])

  useEffect(() => {
    switch (submitState) {
      case SubmitState.DEFAULT:
        setIsLoading(false)
        break
      case SubmitState.SUBMITTING:
        setIsLoading(true)
        break
      case SubmitState.SUCCESS:
        setIsLoading(false)
        // setEmailState({
        //   isValid: false,
        //   input: "",
        // })
        // setSubjectState({
        //   isValid: false,
        //   input: "",
        // })
        // setMessageState({
        //   isValid: false,
        //   input: "",
        // })
        resetTextField(setEmailState)
        resetTextField(setSubjectState)
        resetTextField(setMessageState)
        setIsValidForm(false)
        break
      case SubmitState.FAILURE:
        setIsLoading(false)
        break
    }
  }, [submitState])

  const submit = isValidForm ? (
    // <Style.Submit type="submit" value="Submit" />
    <LoadingInput isLoading={isLoading} loadingColor="white" />
  ) : (
    <Style.DisabledSubmit disabled={true} type="submit" value="Submit" />
  )

  return (
    <Style.Form
      onSubmit={event => {
        // validate first
        event.preventDefault()
        if (isValidForm) {
          onSubmit(emailState.input, subjectState.input, messageState.input)
        }
      }}
    >
      <TextField
        label="Your Email Address *"
        type="email"
        name="email"
        value={emailState.input}
        validation={isValidEmail}
        stateSetter={setEmailState}
        stateGetter={emailState}
      />
      <TextField
        label="Subject *"
        name="subject"
        value={subjectState.input}
        validation={isValidText}
        stateSetter={setSubjectState}
        stateGetter={subjectState}
      />
      <TextField
        label="Message *"
        name="message"
        isMultiLine={true}
        value={messageState.input}
        validation={isValidText}
        stateSetter={setMessageState}
        stateGetter={messageState}
      />
      {submit}
    </Style.Form>
  )
}

export default ContactForm
