import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import "fetch-ponyfill"

import Layout from "../components/layout"
import { Location } from "@reach/router"
import SEO from "../components/seo"
import Style from "../page-styles/contact.style"
import ContactForm, { SubmitState } from "../components/contact-form"
import FadeInLayout from "../components/LayoutTransitions/FadeInLayout"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const ContactPage = ({ data }: Props) => {
  const [submitState, setSubmitState] = useState(SubmitState.DEFAULT)
  const onContactSubmit = (
    sender: string,
    subject: string,
    message: string
  ) => {
    // hit the contact api here
    setSubmitState(SubmitState.SUBMITTING)

    const stopLoading = (wasSuccessful: boolean) => {
      // TODO: check wasSuccessful to see whether or not to clear out the form / show error etc

      const timeout = setTimeout(() => {
        if (wasSuccessful) {
          setSubmitState(SubmitState.SUCCESS)
        } else {
          setSubmitState(SubmitState.FAILURE)
        }
      }, 500)

      return () => {
        clearTimeout(timeout)
      }
    }

    let url
    if (process.env.NODE_ENV === "development") {
      const port = process.env.BACKEND_PORT || "8080"
      url = `http://localhost:${port}`
    } else {
      url = "https://ajbartocci.dev"
    }
    window
      .fetch(`${url}/api/contact`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender: "bartocci.aj@gmail.com",
          subject: subject,
          message: `FROM: ${sender};\nMESSAGE:\n${message}`,
        }),
      })
      .then(response => {
        // console.log(`fetch status = ${response.status}`)
        if (response.status == 200) {
          return response.json()
        } else {
          return response.json().then(data => {
            throw new Error(`error: ${data.result}`)
          })
        }
      })
      .then(data => {
        // console.log(`fetch got ${JSON.stringify(data)}`)
        stopLoading(true)
      })
      .catch(error => {
        // console.log(error.message)
        stopLoading(false)
      })
  }

  const form = (
    <ContactForm onSubmit={onContactSubmit} submitState={submitState} />
  )

  return (
    <Location>
      {({ location }) => {
        return (
          <Layout location={location}>
            <SEO
              title="Contact AJ Bartocci"
              description="Contact information for AJ Bartocci"
            />
            <FadeInLayout>
              <Style.CTA>Let's create exciting things.</Style.CTA>
              <Style.FormContainer>{form}</Style.FormContainer>
            </FadeInLayout>
          </Layout>
        )
      }}
    </Location>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
