import styled from "styled-components"
import { Typography } from "../styleguide/typography"

const { H1 } = Typography

const CTA = styled(H1)`
  margin-top: 7%;
`

const FormContainer = styled.div`
  text-align: center;
  padding-top: 60px;
`

export default {
  CTA,
  FormContainer,
}
