import styled, { css, StyledFunction } from "styled-components"
import { typographyStyles, fontFamilies } from "../styleguide/typography-styles"
import { colors } from "../styleguide/colors"
import {
  ValidatedInput,
  ValidatedTextArea,
  ValidatedInputProps,
} from "./contact-input"

const commonFieldStyle = css`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.subdued};
  border-radius: 4px;
  box-sizing: border-box;
  font-family: ${fontFamilies.body};
  font-size: 16px;
  color: ${colors.primary};
`

const Input = styled(ValidatedInput)`
  ${commonFieldStyle}
`

// interface SomeProps {
//   color?: string
// }

// const Label = styled.label<SomeProps>`
//   /* color: ${colors.primary}; */
//   /* color: red; */
//   color: ${p => p.color ?? colors.primary};
//   font-family: ${fontFamilies.heading};
// `

const Label = styled.label`
  color: ${colors.primary};
  font-family: ${fontFamilies.heading};
`

const MultiLineField = styled(ValidatedTextArea)`
  ${commonFieldStyle}
  height: 100px;
`

const Submit = styled.input`
  width: 100%;
  /* background-color: ${colors.action}; */
  background-color: ${colors.primary};
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  font-size: 14px;
  height: 55px;
  border-radius: 27.5px;
  cursor: pointer;
  -webkit-appearance: none;
  &:hover {
    /* background-color: #00cca3; */
    background-color: black;
  }
`

const DisabledSubmit = styled(Submit)`
  background-color: ${colors.secondary};
  &:hover {
    background-color: ${colors.secondary};
  }
`

const Form = styled.form`
  width: 100%;
  /* min-width: 100px; */
  max-width: 475px;
  display: inline-block;
  text-align: left;
`

export default {
  Input,
  Form,
  Label,
  MultiLineField,
  Submit,
  DisabledSubmit,
}
