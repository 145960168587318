import styled from "styled-components"
import ReactLoading from "react-loading"

import { colors } from "../styleguide/colors"

const Input = styled.input`
  width: 100%;
  /* background-color: ${colors.action}; */
  background-color: ${colors.primary};
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  font-size: 14px;
  height: 55px;
  border-radius: 27.5px;
  cursor: pointer;
  -webkit-appearance: none;
  &:hover {
    /* background-color: #00cca3; */
    background-color: black;
  }
`

const Spinner = styled(ReactLoading)`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Container = styled.div`
  position: relative;
  text-align: center;
`

export default {
  Container,
  Input,
  Spinner,
}
